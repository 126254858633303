import React from 'react';
import Headline from './headline';
import Paragraph from './paragraph';
import styles from '../styles/bio.module.scss';

class What extends React.Component {
  render() {
    return (
      <div className={styles.bio}>
        <Headline text="what" />
        <Paragraph text="I'm multilingual, a word which here means 'fluent in HTML, JS, and CSS'. I've been a front-end developer for over 5 years and like to think that my late-night commit messages have gotten progressively more coherent. Yeah right." />
      </div>
    );
  }
}

export default What;
