import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/paragraph.module.scss';

class Paragraph extends React.Component {
  render() {
    return <p className={styles.paragraph}>{this.props.text}</p>;
  }
}

Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Paragraph;
