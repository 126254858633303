import React from 'react'
import Layout from '../components/layout'
import Logos from '../components/logos'
import About from '../components/about'

const IndexPage = props => (
  <Layout>
    <Logos />
    <About />
  </Layout>
)

export default IndexPage
