import React from 'react';
import Headline from './headline';
import Paragraph from './paragraph';
import styles from '../styles/bio.module.scss';

class Who extends React.Component {
  render() {
    return (
      <div className={styles.bio}>
        <Headline text="who" />
        <Paragraph text="When I'm not at my computer, I'm usually mixing a new cocktail, rewatching the greatest show of all time ('Breaking Bad'), swing dancing with my wife, or reading 'Harold and the Purple Crayon' to my daughters for the 6 billionth time." />
      </div>
    );
  }
}

export default Who;
