import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/headline.module.scss';

class Headline extends React.Component {
  render() {
    return <h3 className={styles.headline}>{this.props.text}</h3>;
  }
}

Headline.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Headline;
