import React from 'react';
import Who from './who';
import What from './what';
import Where from './where';
import styles from '../styles/about.module.scss';

class About extends React.Component {
  render() {
    return (
      <section className={styles.about}>
        <Who />
        <What />
        <Where />
      </section>
    );
  }
}

export default About;
