import React from 'react';
import Headline from './headline';
import Paragraph from './paragraph';
import logoGithub from '../images/logo-github.png';
import logoLinkedIn from '../images/logo-linkedin.png';
import logoCodepen from '../images/logo-codepen.png';
import logoGmail from '../images/logo-gmail.png';
import styles from '../styles/bio.module.scss';

class Where extends React.Component {
  render() {
    return (
      <div className={styles.bio}>
        <Headline text="where" />
        <Paragraph text="I live in Fort Mill, SC, and it's pretty swell. If you're hiring, looking to collaborate, or just have no idea what swing dancing is, hit me&nbsp;up." />
        <div className={styles.bio__contact}>
          <a
            href="https://github.com/CoreyPoff"
            className={styles.bio__iconWrapper}
          >
            <img
              className={styles.bio__icon}
              src={logoGithub}
              alt="Corey Poff's Github"
            />
          </a>
          <a
            href="mailto:mrcpoff@gmail.com?Subject=Hello"
            className={styles.bio__iconWrapper}
          >
            <img
              className={styles.bio__icon}
              src={logoGmail}
              alt="Corey Poff's Email"
            />
          </a>
          <a
            href="https://codepen.io/solomonkane/"
            className={styles.bio__iconWrapper}
          >
            <img
              className={styles.bio__icon}
              src={logoCodepen}
              alt="Corey Poff's Codepen"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/corey-poff-a08413137/"
            className={styles.bio__iconWrapper}
          >
            <img
              className={styles.bio__icon}
              src={logoLinkedIn}
              alt="Corey Poff's LinkedIn"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default Where;
