import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Brands from '@fortawesome/free-brands-svg-icons';
import styles from '../styles/logos.module.scss';

const brandLogos = [
  Brands.faHtml5,
  Brands.faCss3Alt,
  Brands.faJs,
  Brands.faSass,
  Brands.faGit,
  Brands.faReact,
  Brands.faVuejs,
];

const logoElements = brandLogos.map((logo, index) => (
  <FontAwesomeIcon className={styles.logos__logo} icon={logo} key={index} />
));

const Logos = () => (
  <section className={styles.logosWrapper}>
    <div className={styles.logos}>{logoElements}</div>
  </section>
);

export default Logos;
